/** @jsxImportSource @emotion/react */
import React from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

export const FAQ = ({ solxAddress }) => {
  return (
    <div css={{ maxWidth: 500, margin: "50px auto 0 auto", textAlign: "left" }}>
      <Collapse>
        <Panel key={"1"} header={"Is there a vesting period?"}>
          <p>
            SOLX Tokens purchased in the public sale are NOT subject to vesting. You'll receive them in your wallet
            right away
          </p>
        </Panel>
        <Panel key={"2"} header={"Why don't I see SOLX in my wallet?"}>
          <p>
            Please add the SOLX token contact address in your MetaMask/TrustWallet. <br />
            <input type={"text"} disabled={true} value={solxAddress} size={50} />
          </p>
        </Panel>
      </Collapse>
    </div>
  );
};
